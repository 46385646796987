var ALERT_TYPE = {
    'success': (message) =>{
      $('#content-alert')[0].innerHTML = `    
          <p class="alert alert-success-c w-25" style="margin: 8px auto;">
            <strong>${message}</strong>
            <button type="button" class="close bg-white" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </p>
      `;
      setTimeout(() => {
        $('#content-alert')[0].classList.add('fade-out');
        setTimeout(() =>{
          $('#content-alert')[0].children[0].remove();
          $('#content-alert')[0].classList.remove('fade-out');
        }, 999)
      }, 1000);
    },
    'danger': (message) =>{
      $('#content-alert')[0].innerHTML = `    
          <p class="alert alert-danger-c w-25" style="margin: 8px auto;">
            <strong>${message}</strong>
            <button type="button" class="close bg-white" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </p>
      `;
      setTimeout(() => {
        $('#content-alert')[0].classList.add('fade-out');
        setTimeout(() =>{
          $('#content-alert')[0].children[0].remove();
          $('#content-alert')[0].classList.remove('fade-out');
        }, 999)
      }, 1000);
    }
  }
  
  export function toastAlert(type, message){
    if (ALERT_TYPE.hasOwnProperty(type)) {
      ALERT_TYPE[type](message)
    } 
  }