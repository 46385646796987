export function handleCompleteDomainInfo(website_url_selector, website_domain_info_selector, website_domain_selector){
    $(website_url_selector).on('input', debounce((e) => {
        const domain = e.target.value;
        $.ajax({
          url: `/api/v1/websites/get_whois_info_website?domain=${domain}`,
          type: "GET",
          cache: false,
          contentType: 'application/json; charset=utf-8',
          success: function(res) {
            let path = $(website_url_selector)[0].value;
            const domain = new URL(path);
            const hostname = `${domain.host}`;
      
            $(website_domain_info_selector)[0].value = res.data;
            $(website_domain_selector)[0].value = hostname;
          },
          error: function(error){
            console.log('error');
          }
        });
      }, 400));
}


export function debounce(func, wait, immediate) {
  var timeout;
  return function executedFunction() {
      var context = this;
      var args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);     
      if (callNow) func.apply(context, args);
  };
};